import { SvgIcon } from "@mui/material";
export default function safari(props) {


return(<SvgIcon width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.079e-05 19.5833V5.41667C-0.00141117 4.70495 0.137724 3.99995 0.409433 3.34212C0.681143 2.6843 1.08008 2.08661 1.58334 1.58334C2.08661 1.08008 2.6843 0.681143 3.34212 0.409433C3.99995 0.137724 4.70495 -0.00141117 5.41667 1.079e-05H19.5833C20.2951 -0.00141117 21.0001 0.137724 21.6579 0.409433C22.3157 0.681143 22.9134 1.08008 23.4167 1.58334C23.9199 2.08661 24.3189 2.6843 24.5906 3.34212C24.8623 3.99995 25.0014 4.70495 25 5.41667V19.5833C25.0014 20.2951 24.8623 21.0001 24.5906 21.6579C24.3189 22.3157 23.9199 22.9134 23.4167 23.4167C22.9134 23.9199 22.3157 24.3189 21.6579 24.5906C21.0001 24.8623 20.2951 25.0014 19.5833 25H5.41667C4.70495 25.0014 3.99995 24.8623 3.34212 24.5906C2.6843 24.3189 2.08661 23.9199 1.58334 23.4167C1.08008 22.9134 0.681143 22.3157 0.409433 21.6579C0.137724 21.0001 -0.00141117 20.2951 1.079e-05 19.5833Z" fill="white" {...props}/>
<path d="M12.5207 23.3541C18.5152 23.3541 23.3748 18.4946 23.3748 12.5C23.3748 6.50538 18.5152 1.64581 12.5207 1.64581C6.52607 1.64581 1.6665 6.50538 1.6665 12.5C1.6665 18.4946 6.52607 23.3541 12.5207 23.3541Z" fill="url(#paint0_linear_10528_8675)"/>
<path d="M12.5212 22.3916V20.7249" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M12.5212 4.26658V2.59991" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M10.7981 22.2471L11.0875 20.6057M13.9455 4.39745L14.2349 2.7561" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M9.13574 21.7972L9.70578 20.231M15.3348 4.76525L15.9049 3.1991" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M7.56494 21.0761L8.39827 19.6327M16.6274 5.37941L17.4608 3.93604" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M6.16895 20.0822L7.24026 18.8054M17.8195 6.19764L18.8908 4.9209" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M4.92944 18.8581L6.20618 17.7867M18.814 7.20754L20.0907 6.13623" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M3.94678 17.4419L5.39015 16.6086M19.6435 8.37941L21.0868 7.54608" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M3.20532 15.8858L4.77147 15.3157M20.2372 9.68667L21.8034 9.11664" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M2.7688 14.2289L4.41014 13.9395M20.6184 11.0815L22.2598 10.7921" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M2.62866 12.5005H4.29533M20.7536 12.5005H22.4203" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M2.77344 10.7884L4.41478 11.0778M20.6231 13.9358L22.2644 14.2252" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M3.22266 9.11914L4.78881 9.68917M20.2546 15.3182L21.8207 15.8883" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M3.94312 7.5639L5.38649 8.39724M19.6398 16.6264L21.0832 17.4597" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M4.93286 6.13293L6.2096 7.20425M18.8174 17.7834L20.0941 18.8548" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M6.14771 4.92206L7.21902 6.1988M17.7982 18.8066L18.8695 20.0833" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M7.56665 3.92914L8.39998 5.37251M16.6291 19.6258L17.4625 21.0692" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M9.13599 3.19977L9.70602 4.76592M15.3351 20.2317L15.9051 21.7978" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M10.792 2.7691L11.0814 4.41045M13.9394 20.6187L14.2288 22.2601" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M11.6536 22.3557L11.7262 21.5256M13.3059 3.46956L13.3785 2.6394" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M9.96265 22.0684L10.1783 21.2635M14.8694 3.75611L15.0851 2.95117" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M8.33301 21.462L8.68519 20.7067M16.3451 4.27991L16.6973 3.52466" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M6.8418 20.6098L7.31978 19.9271M17.7158 5.08003L18.1938 4.3974" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M5.5293 19.4982L6.11856 18.9089M18.9348 6.09261L19.5241 5.50336" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M4.41504 18.1863L5.09767 17.7083M19.9448 7.31227L20.6274 6.83429" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M3.55444 16.6852L4.3097 16.333M20.7365 8.67304L21.4918 8.32086" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M2.95923 15.0681L3.76417 14.8524M21.2716 10.1613L22.0765 9.94562" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M2.65356 13.365L3.48373 13.2924M21.5397 11.7127L22.3699 11.6401" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M2.66064 11.6401L3.49081 11.7127M21.5468 13.2924L22.377 13.365" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M2.94702 9.94861L3.75196 10.1643M21.2593 14.8554L22.0643 15.0711" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M3.54761 8.31683L4.30286 8.66901M20.7297 16.329L21.4849 16.6811" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M4.41162 6.83337L5.09425 7.31136M19.9414 17.7074L20.624 18.1854" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M5.52832 5.50903L6.11758 6.09829M18.9339 18.9146L19.5231 19.5038" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M6.8313 4.39246L7.30928 5.07508M17.7053 19.9222L18.1833 20.6048" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M8.33813 3.53937L8.69032 4.29462M16.3503 20.7214L16.7024 21.4767" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M9.95288 2.95258L10.1686 3.75751M14.8597 21.2649L15.0753 22.0698" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path d="M11.6448 2.64807L11.7174 3.47823M13.2971 21.5342L13.3697 22.3644" stroke="white" stroke-width="1.003" stroke-linecap="square"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.2228 6.05414L11.6603 11.5333L4.80615 18.9708L13.4103 13.6166L20.2228 6.05414Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.2231 6.05414L11.6606 11.5333L13.4106 13.6166L20.2231 6.05414Z" fill="#FF3B30"/>
<defs>
<linearGradient id="paint0_linear_10528_8675" x1="12.5205" y1="23.357" x2="12.5205" y2="1.64385" gradientUnits="userSpaceOnUse">
<stop stop-color="#1E6FF1"/>
<stop offset="1" stop-color="#28CEFB"/>
</linearGradient>
</defs>
</SvgIcon>)
}