import { SvgIcon } from "@mui/material";
export default function LogoSquare(props) {
  return (
    <SvgIcon
      svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
<rect width="46" height="46" rx="8" fill="#2F86EB"/>
<path d="M22.9965 18.8556C25.8173 18.8556 28.1041 16.5225 28.1041 13.6445C28.1041 10.7665 25.8173 8.43338 22.9965 8.43338C20.1757 8.43338 17.8889 10.7665 17.8889 13.6445C17.8889 16.5225 20.1757 18.8556 22.9965 18.8556Z" fill="white"/>
<path d="M39.6258 13.6445L35.7447 13.6445C34.5914 13.6445 34.3093 14.5916 34.2415 15.0342C34.2218 15.1635 34.2183 15.2491 34.2183 15.2564L34.2183 15.2582C33.7059 18.9812 31.4494 22.1414 28.3198 23.8463C30.164 24.5858 32.217 25.7916 34.0165 27.7532C37.6906 24.7606 40.1738 20.3072 40.6166 15.2637C40.6184 15.2419 40.6202 15.22 40.6219 15.2C40.6255 15.1508 40.6291 15.1034 40.6327 15.0542C40.663 13.7738 39.6258 13.6426 39.6258 13.6426L39.6258 13.6445Z" fill="white"/>
<path d="M10.9604 36.181C10.9497 36.3103 10.88 37.5634 12.1226 37.5634L15.8448 37.5634C15.8448 37.5634 15.9269 37.5707 16.0537 37.5634C16.425 37.5398 17.1748 37.3813 17.3426 36.4815L17.3426 36.4833C17.5729 35.2284 18.1942 34.1118 19.0761 33.274C17.2016 32.9243 14.9093 32.2394 12.7956 30.9043C11.8155 32.4598 11.1693 34.2539 10.9586 36.181L10.9604 36.181Z" fill="white"/>
<path d="M22.9965 25.2069C17.1516 25.2069 12.3279 20.6697 11.7281 14.8648C11.6156 14.3876 11.2782 13.6444 10.257 13.6444L6.3759 13.6444C6.3759 13.6444 5.17978 13.792 5.39223 15.3511C5.39223 15.3511 5.38866 15.3493 5.38509 15.3457C6.22772 24.5038 13.8043 31.6948 22.9965 31.6948C25.8065 31.6948 28.1505 33.7621 28.6486 36.4851C28.8325 37.445 29.6805 37.5561 30.0108 37.5616L30.0857 37.5616C30.1232 37.5616 30.1446 37.558 30.1446 37.558L33.8669 37.558C35.1165 37.558 35.038 36.2903 35.0291 36.1737C34.3507 30.0118 29.2145 25.205 22.9965 25.205L22.9965 25.2069Z" fill="white"/>
    </SvgIcon>
  );
}
