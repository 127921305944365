import { SvgIcon } from "@mui/material";
export default function Add(props) {


return(<SvgIcon  width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">

<path d="M10.5 17.6309C9.96387 17.6309 9.62988 17.2529 9.62988 16.708V13.8252H6.89648C6.36035 13.8252 5.99121 13.5 5.99121 12.9727C5.99121 12.4453 6.36035 12.1113 6.89648 12.1113H9.62988V9.21973C9.62988 8.6748 9.96387 8.29688 10.5 8.29688C11.0273 8.29688 11.3613 8.6748 11.3613 9.21973V12.1113H14.0859C14.6221 12.1113 15 12.4453 15 12.9727C15 13.5 14.6221 13.8252 14.0859 13.8252H11.3613V16.708C11.3613 17.2529 11.0273 17.6309 10.5 17.6309Z" fill="#2B4057"/>
<rect x="0.625" y="3.625" width="19.75" height="18.75" rx="3.375" stroke="#2B4057" stroke-width="1.25" fill="none"/>


</SvgIcon>)

}