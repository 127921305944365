export default {
    UPDATE_PROGRAM:"update-program",
    GENERAL_NOTIFICATION:"new-notification",
    EDIT_DIET:"edited-diet",
    UPDATE_TODO:"update-todo",
    INVITE_CLIENT:"invite-client",
    ACCEPT_INVITE:"accept-invite",
    SEND_PROGRAM:"sent-program",
    SUBSCRIBED_PROGRAM:"subscribed_program",
    LOG_NOTIFICATION:"log-notification",
    ACCEPT_PROGRAM:"accept-program"
    

}