const checkIsDraft = (Program) => {
  let checkList = {
    Title: false,
    Exercise: false,
  };

  if (Program.Title) checkList.Title = true;
  let EmptyWeeks = Program?.ExercisePlan.weeks.find(
    (week) => week.days.find((day) => day.Exercise.length > 0) == null
  );

  if (checkList.Title && !EmptyWeeks) return false;
  else return true;
};

module.exports = { checkIsDraft };
